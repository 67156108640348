.sidebar-container {
    width: 250px;
    height: 100vh;
    background-color: #1abc9c;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: -250px; /* Скрываем меню за экраном */
    padding: 20px 0;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease; /* Плавная анимация появления */
}

.sidebar-container.active {
    left: 0; /* Показываем меню при активном состоянии */
}

.logo-img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}

.sidebar-menu {
    width: 100%;
}

.sidebar-item {
    display: block;
    width: 100%;
    padding: 15px 20px;
    text-decoration: none;
    color: white;
    font-size: 16px;
    text-align: center;
    transition: background-color 0.3s ease, border 0.3s ease;
}

.sidebar-item:hover {
    background-color: rgba(255, 255, 255, 0.37);
}

.active {
}

.hamburger {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
    cursor: pointer;
}

.white div {
    width: 30px;
    height: 3px;
    margin: 5px;
    transition: all 0.3s ease;
    background-color: white;
}

.black div {
    width: 30px;
    height: 3px;
    margin: 5px;
    transition: all 0.3s ease;
    background-color: black;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
    .sidebar-container {
        width: 100%;
        left: -100%; /* Скрываем меню для мобильных */
    }

    .sidebar-container.active {
        left: 0; /* Появляется меню при активном состоянии */
    }

    .sidebar-item {
        padding: 15px;
        font-size: 18px;
    }
}

.logo-img {
    width: 100%;
    margin-bottom: 20px;
}

.pending-count-point {
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    background-color: #ff9800;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.pending-count-badge {
    background-color: #ff9800;
    color: white;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 12px;
    font-weight: bold;
    position: relative;
    left: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
