/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
}

.modal-content {
}

.button-container {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.modal-button {
    font-family: 'LXGW WenKai Mono TC', serif;
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.modal-button.confirm {
    background-color: #4caf50;
    color: white;
}

.modal-button.cancel {
    background-color: #f44336;
    color: white;
}

.modal-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
