

.container {
    position: absolute;
    top: 0;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    visibility: hidden;
}

.item {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

img {
    width: auto;
}

.caption {
    font-weight: bold;
    color: #ffffff;
}