/* ImageList.css */

/* Root Variables for Colors and Fonts */
:root {
    --primary-color: #007bff;
    --secondary-color: #f4f4f9;
    --background-color: #f0f8ff;
    --text-color: #333;
    --overlay-bg: rgb(84, 133, 203);
    --hover-bg: #f1f4f9;
    --toggle-bg-off: #ccc;
    --toggle-bg-on: var(--primary-color);
    --spinner-border-color: #5485cb;;
    --spinner-bg-color: #f3f3f3;
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    --box-shadow-hover: 0 8px 16px rgba(0, 0, 0, 0.2);
    --font-family: 'LXGW WenKai Mono TC', serif;
    --border-radius: 12px;
}

.image-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    min-height: 100vh;
    background-color: var(--secondary-color);
    font-family: var(--font-family), serif;
    width: 100%;
    box-sizing: border-box;
}

.image-title {
    font-family: 'LXGW WenKai Mono TC', serif;
    font-size: 24px;
    color: white;
    text-align: center;
    margin-bottom: 20px;
    background-color: #5485cb;
    padding: 10px;
    border-radius: var(--border-radius);
    width: 100%;
    box-sizing: border-box;
}

.selected-room {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    background-color: #fff;
    padding: 10px;
}

.selected-room img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
}

.input-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.input-container.open {
    max-height: 1000px; /* Adjust this value based on your content */
    opacity: 1;
    transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
}

.toggle-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    caret-color: transparent;
}

.toggle-button {
    background-color: #5485cb;
    width: 100%;
    color: white;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-family: var(--font-family), serif;
    font-size: 16px;
    transition: background-color 0.3s ease;
    box-shadow: var(--box-shadow);
}

.toggle-button:hover {
    background-color: #0056b3; /* Darker shade of primary color */
}

.input-container__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.input-container label {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    flex: 1;
    min-width: 200px;
}

.input-container label:hover {
    border-color: var(--primary-color);
    background-color: var(--hover-bg);
}

.input-container input[type="range"] {
    -webkit-appearance: none;
    width: 70%;
    min-width: 70%;
    max-width: 70%;
    alignment: right;
    height: 8px;
    background: #ddd;
    outline: none;
    opacity: 0.9;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
    touch-action: manipulation;
    caret-color: transparent;
}

.input-container input[type="range"]:focus {
    opacity: 1;
}

.input-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: var(--primary-color);
    cursor: pointer;
    border-radius: 50%;
    transition: background 0.3s;
}

.input-container input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: var(--primary-color);
    cursor: pointer;
    border-radius: 50%;
    transition: background 0.3s;
}

.toggle-switch {
    display: flex;
    align-items: center;
}

.toggle-switch input {
    display: none;
}

.toggle-row {
    display: flex;
    flex-wrap: wrap;
    caret-color: transparent;
    border-radius: 8px;
    width: 100%;
}

.toggle-row .toggle-switch label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 15px;
}

.toggle-row .slider {
    position: relative;
    width: 50px;
    height: 24px;
    border-radius: 12px;
    background-color: var(--toggle-bg-off);
    transition: background-color 0.4s;
    margin-right: 10px;
}

.toggle-row .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.4s;
}

.toggle-row input:checked + .slider {
    background-color: var(--toggle-bg-on);
}

.toggle-row input:checked + .slider:before {
    transform: translateX(26px);
}

.image-item {
    height: 180px;
    cursor: pointer;
    overflow: hidden;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-item:hover {
    transform: translateY(-5px);
    box-shadow: var(--box-shadow-hover);
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
}

.image-item__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--overlay-bg);
    color: white;
    padding: 8px;
    text-align: center;
    font-size: 14px;
    opacity: 70%;
}

.price-row {
    border-radius: 8px;
    width: 50%;
    flex-wrap: wrap;
}

.price-row label {
    font-family: 'LXGW WenKai Mono TC', serif;
    font-size: 18px;
    color: #5485cb;
    display: flex;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner {
    border: 10px solid var(--spinner-bg-color);
    border-top: 10px solid var(--spinner-border-color);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
    .image-list-container {
        padding: 15px;
    }

    .selected-room {
        flex-direction: column;
        height: 100%;
    }

    .input-container__row {
        flex-direction: column;
    }

    .toggle-row {
        flex-direction: column;
    }

    .image-item {
        height: 150px;
    }

    .price-row label {
        font-size: 16px;
    }

    .image-title {
        font-size: 20px;
    }
}

.submit-button-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    width: 100%;  /* Контейнер на всю ширину */
}

.submit-button {
    background-color: #5485CBFF;
    color: white;
    padding: 15px 30px;
    font-size: 18px;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    width: 100%;  /* Кнопка занимает всю ширину */
    text-align: center;
    transition: background-color 0.3s ease, transform 0.2s ease;
    max-width: 1200px;  /* Максимальная ширина для больших экранов */
}

.submit-button:hover {
    background-color: #0056b3; /* Темно-синий цвет при наведении */
    transform: translateY(-2px);  /* Легкий эффект "всплытия" при наведении */
}

.submit-button:active {
    background-color: #003d7a; /* Еще более темный цвет при нажатии */
    transform: translateY(0);  /* Возвращаем кнопку на место при нажатии */
}

