/* style.css */

/* Global Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --primary-color: #007bff;
    --secondary-color: #f4f4f9;
    --background-color: #f0f8ff;
    --text-color: #333;
    --header-bg: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    --button-bg: #00bfff;
    --button-hover-bg: #009acd;
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: var(--font-family), serif;
}

body {
    background: var(--background-color);
    color: var(--text-color);
}

button {
    font-family: 'LXGW WenKai Mono TC', serif !important;
}

/* Header */
.header {
    background: var(--header-bg);
    color: #fff;
    padding: 20px 0;
}

.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.header__logo {
    display: flex;
    align-items: center;
}

.header__logo-text {
    margin-left: 10px;
}

.header__logo-text-main {
    font-size: 24px;
    font-weight: bold;
}

.header__logo-text-sub {
    font-size: 14px;
    color: #b3e0ff;
}

.header__nav {
    display: flex;
    align-items: center;
}

.header__nav-list {
    list-style: none;
    display: flex;
}

.header__nav-item {
    margin-left: 20px;
}

.header__nav-link {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s;
}

.header__nav-link:hover,
.header__nav-link:focus {
    color: #b3e0ff;
}

.header__menu-btn {
    display: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

/* Responsive Header */
@media (max-width: 768px) {
    .header__container {
        flex-direction: column;
        align-items: stretch;
    }

    .header__nav-list {
        flex-direction: column;
        align-items: stretch;
        display: none;
    }

    .header__nav-list.active {
        display: flex;
    }

    .header__nav-item {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .header__menu-btn {
        display: block;
    }
}

/* Hero Section */
.hero {
    background: linear-gradient(to top, dodgerblue, whitesmoke);
    color: #fff;
    padding: 100px 20px;
    text-align: center;
}

.hero__title {
    font-size: 2em;
    margin-bottom: 20px;
    color: darkblue;
}

.hero__btn {
    background: var(--button-bg);
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
    display: inline-block;
    transition: background 0.3s;
}

.hero__btn:hover,
.hero__btn:focus {
    background: var(--button-hover-bg);
}

/* About Section */
.about {
    padding: 50px 20px;
    background: var(--background-color);
    text-align: center;
}

.about__title {
    color: #0073e6;
    font-size: 2em;
    margin-bottom: 20px;
}

.about__text {
    font-size: 18px;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
}

/* Features Section */
.features {
    padding: 50px 20px;
    background: #e6f7ff;
    text-align: center;
}

.features__title {
    color: #0073e6;
    font-size: 2em;
    margin-bottom: 20px;
}

.features__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.features__item {
    margin: 10px 0;
    font-size: 18px;
}

/* Contact Section */
.contact {
    padding: 50px 20px;
    background: var(--background-color);
    text-align: center;
}

.contact__title {
    color: #0073e6;
    font-size: 2em;
    margin-bottom: 20px;
}

.contact__form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: darkslategrey;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact__form-group {
    margin-bottom: 15px;
}

.contact__form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #fff;
}

.contact__form-input,
.contact__form-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact__btn {
    background: var(--button-bg);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;
    transition: background 0.3s;
}

.contact__btn:hover,
.contact__btn:focus {
    background: var(--button-hover-bg);
}

/* Image Title Specific Styling */
.image-title h4 {
    margin: 0;
}

/* Carousel Container */
.carousel-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: var(--border-radius);
    border: var(--overlay-bg) solid transparent;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.carousel-container.open {
    max-height: 200px; /* Adjust this value based on your content */
    opacity: 1;
    border-radius: var(--border-radius);
    border: var(--overlay-bg) solid transparent;
    transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
}

/* Ensure carousel items are visible */
.carousel-item-padding-40-px {
    padding: 0 10px;
}

/* Additional Responsive Styles */
@media (max-width: 768px) {
    .hero {
        padding: 60px 20px;
    }

    .hero__title {
        font-size: 1.5em;
    }

    .about__text {
        font-size: 16px;
    }

    .features__item {
        font-size: 16px;
    }

    .contact__form {
        padding: 15px;
    }
}
